.landing {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.landing .text-primary {
    color: #000058 !important;
}

.landing .footer-heading {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;

}

.landing .footer-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.landing .header {
    font-size: 30px;
    font-weight: 900;
    line-height: 35px;
}

.landing .subheading {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24.38px;
}
.landing .subheading-2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14.63px;
}

.landing .name {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
}


.landing .list {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.landing .action {
    border-radius: 68px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    letter-spacing: 0em;
    text-align: left;

}

.bg-lightGrey {
    background-color: #fafafa;
}

.landing .icon {
    width: 28px;
    height: 28px;
}

.landing .testimonial {
    width: 255px !important;
    height: 375px !important;
    background-color: white;
    border-radius: 5px;
}

.landing .testimonial-img {
    width: 103px;
    height: 103px;
}

.landing .card-body {
    height: 210px;
}

.landing .accordion-item {
    margin-top: 16px;
    border-radius: 5px !important;
    border: white;
}

.landing .accordion-header > button {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    color: #000058 !important;
}

.landing .accordion-header > button[aria-expanded=true] {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    background-color: white !important;
    color: #000058 !important;
}

.landing .accordion-header > button::after {
    background-color: #ebecff;
    border-radius: 50%;
    padding: 10px;
}
.landing .screenshots {
    height: 150px;
    width: 330px;
}

.nav-menu .nav-link {
    font-family: 'Montserrat', sans-serif;
    color: #000058 !important;
}

.nav-menu .nav-link .active {
    font-weight: 600;
}

.nav-logo {
    height: 42px;
    width: 40px;
}

.demo .modal-header {
    border-bottom: #5860d2;
}

.demo .close {
    color: #fff;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .landing .header {
        font-size: 50px;
        font-weight: 900;
        line-height: 64px;
        letter-spacing: 0em;
        text-align: left;
    }

    .landing .footer-heading {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
    }

    .landing .footer-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }

    .landing .footer-small-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
    }

    .landing .action {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .landing .list {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 23px;
    }

    .landing .accordion-header > button {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
    }

    .landing .accordion-header > button[aria-expanded=true] {
        font-size: 20px;
    }

    .landing .subheading {
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 35px;
    }

    .landing .subheading-2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    .landing .name {
        font-size: 20px;
        font-weight: 800;
        line-height: 25px;
    }

    .landing {
        font-size: 18px;
        line-height: 26px;
    }

    .landing .bold {
        font-weight: 600;
    }

    .landing .icon {
        width: 60px;
        height: 60px;
    }

    .landing .testimonial {
        width: 340px !important;
        height: 550px !important;
        background-color: white;
        border-radius: 5px;
    }

    .landing .testimonial-img {
        width: 140px;
        height: 140px;
    }

    .landing .card-body {
        height: 280px;
    }

    .landing .screenshots {
        height: 250px;
        width: 600px;
    }

    .nav-header {
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
    }

    .nav-menu .nav-link {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #000058 !important;
    }

    .nav-menu .nav-link .active {
        font-weight: 600;
        border-bottom: solid #5860d2 !important;
    }

    .nav-logo {
        height: 50px;
        width: 47px;
    }

}

@media screen and (min-width: 992px) {
    .landing .hero {
        background-image: url("../public/landing/hero.jpg");
        background-repeat: no-repeat;
        background-size: 1037px 628px;
        background-position: right;
    }

    .landing .hero-2 {
        background-image: url("../public/landing/hero-2.jpg");
        background-repeat: no-repeat;
        background-size: 900px 650px;
        background-position: right;
    }
}

@media screen and (min-width: 1600px) {
    .landing .header {
        font-size: 50px;
        font-weight: 900;
        line-height: 64px;
        letter-spacing: 0em;
        text-align: left;
    }

    .landing .footer-heading {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
    }

    .landing .footer-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    .landing .footer-small-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .landing .action {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .landing .list {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    .landing .accordion-header > button {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
    }

    .landing .accordion-header > button[aria-expanded=true] {
        font-size: 24px;
    }

    .landing .subheading {
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 39px;
    }

    .landing .subheading-2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
    }

    .landing .name {
        font-size: 24px;
        font-weight: 800;
        line-height: 29px;
    }

    .landing {
        font-size: 20px;
        line-height: 28px;
    }

    .landing .bold {
        font-weight: 600;
    }

    .landing .icon {
        width: 80px;
        height: 80px;
    }

    .landing .testimonial {
        width: 344px !important;
        height: 570px !important;
        background-color: white;
        border-radius: 5px;
    }

    .landing .testimonial-img {
        width: 162px;
        height: 162px;
    }

    .landing .card-body {
        height: 290px;
    }

    .landing .screenshots {
        height: 250px;
        width: 600px;
    }

    .nav-header {
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }

    .nav-menu .nav-link {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #000058 !important;
    }

    .nav-menu .nav-link .active {
        font-weight: 600;
        border-bottom: solid #5860d2 !important;
    }

    .nav-logo {
        height: 52px;
        width: 49px;
    }

    .landing .hero {
        background-image: url("../public/landing/hero.jpg");
        background-repeat: no-repeat;
        background-size: 1037px 628px;
        background-position: right;
    }

    .landing .hero-2 {
        background-image: url("../public/landing/hero-2.jpg");
        background-repeat: no-repeat;
        background-size: 900px 650px;
        background-position: right;
    }
}