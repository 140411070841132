html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.rounded-form {
    border-radius: 30px !important;
}

.footer-style a {
    color: #5860d2 !important;
    text-decoration: none;
}

.form-check-input:checked {
    background-color: #5860d2 !important;
    border-color: #5860d2 !important;
}

.padding-y0 td,th{
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
}

.emr input {
    border-radius: 30px !important;
    color: black !important;
}

.emr select {
    border-radius: 30px !important;
}

.emr .form-control {
    color: #000058 !important;
}

.emr .rbt-input-multi {
    border-radius: 30px !important;
}

.rbt-token {
    color: #5860d2 !important;
    background-color: #ebecff !important;
}

a {
    color: inherit;
    text-decoration: none;
}
.blogs > img {
    width: 100%;
}

.waiting-list{
    z-index: 2000 !important;
}

* {
    box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }

    body {
        color: white;
        background: black;
    }
}
.add-text {
    color: maroon;
}

.banner {
    --d: 6px; /* folded part */
    --c: red; /* color */
    --f: 16px; /* ribbon font-size */
    font-weight: bold;
    height: 15rem;
    position: relative;
    border-radius: 1rem;
    background: linear-gradient(to right, #d8cba6, #f8f6f0, #d8cba6, #f8f6f0);
}

.banner::before {
    content: attr(data-ribbon);
    font-size: var(--f);
    /* I : position & coloration */
    position: absolute;
    color: white;
    top: 0;
    right: 0;
    transform: translate(29.29%, -100%) rotate(45deg);
    transform-origin: bottom left;
    padding: 5px 35px calc(var(--d) + 5px);
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d) no-repeat var(--c);
    /* II : clipping */
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - var(--d)) calc(100% - var(--d)), var(--d) calc(100% - var(--d)), 0 100%);
    /* III : masking */
    -webkit-mask: linear-gradient(135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0) bottom right;
    -webkit-mask-size: 300vmax 300vmax;
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
}

.location-desktop .dropdown-menu.show {
    width: fit-content !important;
}

.rbt-close-content {
    display: none;
}

.rbt-aux .btn-close {
    width: 0.4rem;
    height: 0.4rem;
}

.rbt-aux .rbt-close {
    margin-top: 0rem;
}

.session .form-control {
    width: auto !important;
}

.fs-7 {
    font-size: 0.875rem;
}

.fs-8 {
    font-size: 0.75rem;
}

.delete-case-audit {
    padding-top: 4rem;
}

/* Active tab */
.nav-tabs > li > button.active ,
.nav-tabs > li > button.active:hover,
.nav-tabs > li > button.active:focus {
    background-color: #5860D2 !important;
    color: white !important;
}
.nav-tabs > li > button {
    color: #5860D2 !important;
}

/* Disabled tab */
.nav-tabs > li.disabled > button {
    background-color: lightgray !important;
    color: darkgray !important;
}

.sidebar-menu {
    min-height: 100vh;
    background-color: #ebecff ;
}

.sidebar-menu .nav {
    width:100%
}

/* Active tab */
.sidebar-menu .nav-item > a.active ,
.sidebar-menu .nav-item > a.active:hover,
.sidebar-menu .nav-item > a.active:focus {
    background-color: white !important;
    border-radius: 0 !important;
}

/* Disabled tab */
.sidebar-menu .nav-item > a {
    padding-left: 2rem;
    color: #000058 !important;
}
.sidebar-menu .nav-item > a:hover {
    color: #5860D2 !important;
}

/* Active tab */
.sidebar-menu .sidebar-menu-sub .sidebar-menu-nav-item > a.active ,
.sidebar-menu .sidebar-menu-sub .sidebar-menu-nav-item > a.active:hover,
.sidebar-menu .sidebar-menu-sub .sidebar-menu-nav-item > a.active:focus {
    background-color: white !important;
    border-radius: 0 !important;
}

/* Disabled tab */
.sidebar-menu .sidebar-menu-sub .sidebar-menu-nav-item > a {
    padding-left: 4rem;
    color: #5860D2 !important;
}
.sidebar-menu .sidebar-menu-sub .sidebar-menu-nav-item > a:hover {
    color: #000058 !important;
}

.sidebar-menu .sidebar-menu-sub > button > a {
    padding-left: 2rem;
    color: #5860D2 !important;
}

.sidebar-menu .sidebar-menu-sub .collapsed> a:after {
    content: " ▼" !important;
}

.sidebar-menu .sidebar-menu-sub > button> a:after {
    content: " ▶";
}
.sidebar-menu .sidebar-menu-sub > button > a :hover {
    color: #000058 !important;
}

.left-tabs .tab-pane {
    margin: 2rem;
}

.left-tabs .nav {
    margin-top: 2rem;
}

.tbody-tr {
    color: #000058;
}

.edit {
    color: darkslategrey;
    text-decoration: none;
}

.remove {
    color: maroon;
    text-decoration: none;
}

.accordian-base-colour > button {
    background-color: #ebecff !important;
}

.accordion-item {
    margin-top: 16px;
    border-radius: 5px !important;
    border: white;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

div.printElements {
    page-break-after: always;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media print {
    .multi-pager div {
        break-inside: avoid;
    }
}

.pres_medicine {
    min-width: 210px;
}
.pres_dosage {
    width: 100px;
}
.template-input {
    min-width: 210px;
}
@media screen and (min-width: 768px) {
    .pres_medicine {
        min-width: 260px;
    }
    .pres_medicine .dropdown-menu {
        min-width: 510px !important;
    }

    .bill .dropdown-menu {
        min-width: 510px !important;
    }
    .template-input {
        min-width: 260px;
    }
}

@media screen and (min-width: 992px) {
    .pres_medicine {
        min-width: 410px;
    }

    .bill .dropdown-menu {
        min-width: 710px !important;
    }
    .pres_medicine .dropdown-menu {
        min-width: 710px !important;
    }

    .template-input {
        min-width: 410px;
    }
}

@media screen and (min-width: 1600px) {

}